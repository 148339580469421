import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import {
  DeleteAccount,
  getActivity,
  getCustomerdetails,
  suspendAccount,
} from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import Logo from "../Assets/Images/Profile-icon.png";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";

export default function CustomerProfile() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [showI, setShowI] = useState(false);

  const handleClose = () => setShowI(false);
  const handleShow = () => setShowI(true);

  const [UserDeatils, setUserDetails] = useState("");
  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);
  const [ActivityDetails, setActivityDetails] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getCustomerdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setUserDetails(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  useEffect(() => {
    const getactivitydata = async () => {
      const data = await dispatch(getActivity({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setActivityDetails(data?.payload?.data);
      }
    };
    getactivitydata();
  }, [id]);

  const handleSuspend = async () => {
    const data = await dispatch(suspendAccount({ userId: id, type: 0 }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setshow(false);
      navigate("/user-management");
    }
  };

  const handleDelete = async () => {
    const data = await dispatch(DeleteAccount({ id: id }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setshow(false);
      navigate("/user-management");
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2> User Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/user-management">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0 gap-3"
            >
              {/* <div className="cmn-btn">
                <Link to="#" className="active-btn-select">Overview</Link>
              </div>
              <div className="cmn-btn">
                <Link to="#">All Services</Link>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row className="">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <div className="d-flex">
                        <span className="add-icon-verify">
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              UserDeatils?.profile_image
                                ? `${url}${UserDeatils?.profile_image}`
                                : Logo
                            }
                          />
                        </span>
                        <div className="figure-caption">
                          <div className="customer-form-new border-none mb-4">
                            <div className="">
                              <h3>Profile info</h3>
                              <Row>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Agent name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={UserDeatils?.full_name || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Agent Id</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={UserDeatils?.unique_id || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Company name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={UserDeatils?.company_name || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Email address </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={UserDeatils?.email || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={UserDeatils?.country || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={UserDeatils?.city || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">Licensed Agent</h2>
                        <Row>
                          {/* <Col lg={12} className="mb-3   ">
                            {UserDeatils?.licensed_doc ? (
                              <img
                                className="profile-add-img"
                                src={`${url}${UserDeatils?.licensed_doc}`}
                                alt="Licensed Document"
                                onClick={handleShow} 
                                style={{ cursor: 'pointer',width: "150px", height:"150px" }}
                              />
                            ) : (
                              <p>Document not found</p>
                            )}
                          </Col> */}
                          <Col lg={12} className="mb-3">
                            {UserDeatils?.licensed_doc ? (
                              // Check if the file is a PDF or an image
                              UserDeatils.licensed_doc.endsWith(".pdf") ? (
                                <a
                                
                                  href={`${url}${UserDeatils.licensed_doc}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    color: "blue",
                                  }}
                                >
                                  <p>View PDF Document Click here to open</p>
                                </a>
                              ) : (
                                <img
                                  className="profile-add-img"
                                  src={`${url}${UserDeatils.licensed_doc}`}
                                  alt="Licensed Document"
                                  onClick={handleShow}
                                  style={{
                                    cursor: "pointer",
                                    width: "150px",
                                    height: "150px",
                                  }}
                                />
                              )
                            ) : (
                              <p>Document not found</p>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">SOCIAL MEDIA</h2>
                        <div className="default-links">
                          <ul>
                            <li>
                              <h6>Facebook</h6>
                              <p>{UserDeatils?.facebook || "N/A"}</p>
                            </li>
                            <li>
                              <h6>SnapChat </h6>
                              <p>{UserDeatils?.snapchat || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Instagram</h6>
                              <p>{UserDeatils?.instagram || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Youtube </h6>
                              <p>{UserDeatils?.youtube || "N/A"}</p>
                            </li>
                            <li>
                              <h6>X</h6>
                              <p>{UserDeatils?.twitter || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Booking </h6>
                              <p>{"N/A"}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 mt-4 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Activity</h2>
                        <div className="active-default-links">
                          {ActivityDetails?.map((details) => {
                            return (
                              <ul>
                                <li>
                                  <h6>{details?.title}</h6>
                                  <p>
                                    {details?.createdAt
                                      ? new Date(details?.createdAt)
                                          .toISOString()
                                          .slice(0, 10)
                                          .replace(/-/g, "/")
                                      : "N/A"}
                                  </p>
                                </li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={6} className=" mb-4">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Delete Account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            Once you delete your account, you can not retrieve
                            the account. Please be certain.
                          </p>
                        </Figure.Caption>
                        <div
                          class="dlt-ac-btn mt-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => setshowd(true)}
                        >
                          <a>Delete Account</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className=" mb-4">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Suspend account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            You will not be able to receive messages,
                            notifications for up to 24hours.
                          </p>
                        </Figure.Caption>
                        <div
                          class="dlt-ac-btn mt-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => setshow(true)}
                        >
                          <a>Suspend Account</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Suspend this Account ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleSuspend}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showI} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Licensed Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={`${url}${UserDeatils?.licensed_doc}`}
            alt="Licensed Document"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "500px",
              // objectFit: 'contain',
            }}
          />
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
