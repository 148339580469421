import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button,Modal } from "react-bootstrap";
import { AllTeams, DeletePlayer, Deleteteam, DowanloadTeams } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import Logo from "../Assets/Images/Profile-icon.png";
import Pagination from "../Components/Layout/pagination";



export default function Teams() {

  const url = process.env.REACT_APP_FILE_BASE_URL;
  
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [TeamsList, setTeamsList] = useState([]);
  const [pagi, setpagi] = useState("");
  const [showd, setshowd] = useState(false);
  const [teamID, setteamID] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };

    dispatch(AllTeams(searchData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setTeamsList(res?.payload?.data?.data?.teams);
          setpagi(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err, "error occur");
      });
  }, [page, limit, search, flag]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadTeams());
      console.log(response);

      const allData = response?.payload?.data?.data?.teams;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handleProfile = (id) => {
    navigate(`/teamdetails-overview/${id}`);
  };

  const handleDeletePlayer = () => {
    dispatch(Deleteteam({ id: teamID }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Teams Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserVerification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing {1 + (pagi?.currentPage - 1) * limit} -{" "}
                {Math.min(pagi?.currentPage * limit, pagi?.totalCount)} of{" "}
                {pagi?.totalCount} results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Team Name</th>
                <th>Team logo</th>
                <th>Team Country</th>
                <th>Agent</th>
                <th>Total Players</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {TeamsList?.map((res,index) => {
                const serialNumber =
                  ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleProfile(res?._id)}
                      >
                        <b>{res?.team_name || "N/A"}</b>
                      </p>
                    </td>
                    <td className="user-img">
                      <img  src={
                              res?.profile_image
                                ? `${url}${res?.profile_image}`
                                : Logo
                            } />
                    </td>
                    <td>{res?.team_country  || "N/A"}</td>
                    <td></td>
                    <td>11</td>
                    <td>
                      <Button className="account-btn common-colr-btn"  onClick={() => {
                          setshowd(true);
                          setteamID(res?._id);
                        }}>
                        Remove
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Remove this Team ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeletePlayer}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpage}
        />
      </div>

    </Layout>
  );
}
