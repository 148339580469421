import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { getPlayersdetails } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import PlayerNav from "../Components/PlayerNav";
import Logo from "../Assets/Images/Profile-icon.png";
import ModalImage from "react-modal-image";

export default function PlayerProfileOverview() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [playerdata, setPlayerdata] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getPlayersdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setPlayerdata(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  const images = playerdata?.photos?.map((photo) => `${url}${photo}`) || [];

  const playerCvUrl = playerdata?.player_cv?.includes("public")
    ? `${url}${playerdata.player_cv}`
    : null;

 

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Player Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/players">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>

            <PlayerNav id={id} />
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <Figure className="d-flex">
                        <span className="">
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              playerdata?.profile_image
                                ? `${url}${playerdata?.profile_image}`
                                : Logo
                            }
                          />
                        </span>
                        <div className="figure-caption">
                          <div className="customer-form-new border-none mb-4">
                            <div className="">
                              <h3>Player Info</h3>
                              <Row>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Player Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={playerdata?.full_name || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Player ID</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={playerdata?.unique_id || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Agent</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        playerdata?.associate_agent_name ||
                                        "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Figure>
                    </Col>
                  </Row>
                </div>
                <div className="user-profile-main mt-4">
                  <Row>
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4">
                        <div className="">
                          <h3 className="">Profile</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.gender === 0 ? "Male" : "Female"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Age</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.age || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Availability</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.availability_status
                                    ?.map((status) =>
                                      status === 0
                                        ? "Free"
                                        : status === 1
                                        ? "Not Free"
                                        : status === 2
                                        ? "On loan"
                                        : status === 3
                                        ? "Free in the coming transfer period"
                                        : "Open for discussions"
                                    )
                                    .join(", ")}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Playing position</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.positions
                                    ?.map((position) =>
                                      position === 0
                                        ? "GK"
                                        : position === 1
                                        ? "Right Back"
                                        : position === 2
                                        ? "Left Back"
                                        : position === 3
                                        ? "Left Central Defender"
                                        : position === 4
                                        ? "Right Central Defender"
                                        : position === 5
                                        ? "Midfield Defender"
                                        : position === 6
                                        ? "Box to Box"
                                        : position === 7
                                        ? "No.10"
                                        : position === 8
                                        ? "Right Winger"
                                        : position === 9
                                        ? "Left Winger"
                                        : position === 10
                                        ? "Striker"
                                        : "2nd Striker"
                                    )
                                    .join(", ")}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Place if Birth</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.place_of_birth || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Nationality</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.nationality
                                    ?.map((NATIONALITY) =>
                                      NATIONALITY === 0
                                        ? "Angolan"
                                        : NATIONALITY === 1
                                        ? "American"
                                        : NATIONALITY === 2
                                        ? "Andorran"
                                        : "Antiguans"
                                    )
                                    .join(", ")}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Nationality Id</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.nationality_id === 0
                                      ? "EU"
                                      : "Non EU "
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>National Team Player</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.national_team_player === 0
                                      ? "No"
                                      : playerdata?.national_team_player === 1
                                      ? "yes"
                                      : playerdata?.national_team_player === 2
                                      ? "Former National Player"
                                      : "Youth National Player"
                                  }
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>National team</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.national_team || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Foot</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.foot
                                    ?.map((data) =>
                                      data === 0
                                        ? "Right"
                                        : data === 1
                                        ? "Left"
                                        : "Both"
                                    )
                                    .join(", ")}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Height</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.height || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Language</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.languages?.map((Lang) =>
                                    Lang === 0
                                      ? "Afrikaans" 
                                      : Lang === 1
                                      ? "Albanian"
                                      : Lang === 2
                                      ? "Amharic"
                                      : "Akan"
                                  ).join(', ')}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Educational level</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.education_level === 0
                                      ? "Primary School"
                                      : playerdata?.education_level === 1
                                      ? "High School"
                                      : playerdata?.education_level === 2
                                      ? "College"
                                      : "University"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Marital Status</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.martial_status === 0
                                      ? "Single"
                                      : playerdata?.martial_status === 1
                                      ? "Engaged"
                                      : "Married"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Children</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.children}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Hobbies/Interests</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.hobbies || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                          </Row>
                          <h3 className="mb-3 mt-4">Current team</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="">
                                <Form.Label>Team name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.team_name || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="">
                                <Form.Label>country</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.team_country || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="user-profile-main mt-4">
                  <Row>
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4">
                        <div className="">
                          <h3 className="">Contact</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.phone_number
                                      ? playerdata.country_code
                                        ? `+${playerdata.country_code} ${playerdata.phone_number}`
                                        : `${playerdata.phone_number}`
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.email || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Home address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={playerdata?.address || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Players lawyer</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.player_lawyer_name || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label> Layer Contact number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.player_lawyer_phone_number ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Managing company</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.player_managing_company_name ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  {" "}
                                  Manging Company Contact number
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.player_managing_company_phone_number
                                      ? playerdata.player_managing_company_country_code
                                        ? `+${playerdata.player_managing_company_country_code} ${playerdata.player_managing_company_phone_number}`
                                        : `${playerdata.player_managing_company_phone_number}`
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Sponsor Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.player_sponsors_name || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>sponsor Contact Numaber</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    playerdata?.player_sponsors_phone_number
                                      ? playerdata.player_sponsors_country_code
                                        ? `+${playerdata.player_sponsors_country_code} ${playerdata.player_sponsors_phone_number}`
                                        : `${playerdata.player_sponsors_phone_number}`
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">SOCIAL MEDIA</h2>
                        <div className="default-links">
                          {/* <ul>
                            <li>
                              <h6>Facebook</h6>
                              <p>{playerdata?.facebook || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Instagram </h6>
                              <p>{playerdata?.instagram || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Snapchat</h6>
                              <p>{playerdata?.snapchat || "N/A"}</p>
                            </li>
                            <li>
                              <h6>X </h6>
                              <p>{playerdata?.twitter || "N/A"}</p>
                            </li>
                          </ul> */}
                          <ul>
                            <li>
                              <h6>Facebook</h6>
                              {playerdata?.facebook ? (
                                <a
                                  href={playerdata.facebook}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="video-link"
                                >
                                  {playerdata.facebook}
                                </a>
                              ) : (
                                <p>N/A</p>
                              )}
                            </li>
                            <li>
                              <h6>Instagram</h6>
                              {playerdata?.instagram ? (
                                <a
                                  href={playerdata.instagram}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="video-link"
                                >
                                  {playerdata.instagram}
                                </a>
                              ) : (
                                <p>N/A</p>
                              )}
                            </li>
                            <li>
                              <h6>Snapchat</h6>
                              {playerdata?.snapchat ? (
                                <a
                                  href={playerdata.snapchat}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="video-link"
                                >
                                  {playerdata.snapchat}
                                </a>
                              ) : (
                                <p>N/A</p>
                              )}
                            </li>
                            <li>
                              <h6>X</h6>
                              {playerdata?.twitter ? (
                                <a
                                  href={playerdata.twitter}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="video-link"
                                >
                                  {playerdata.twitter}
                                </a>
                              ) : (
                                <p>N/A</p>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Video Link</h2>
                        <div className="default-links">
                          <ul>
                            <li>
                              {playerdata?.video_link ? (
                                <a
                                  href={playerdata.video_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="video-link"
                                >
                                  {playerdata.video_link}
                                </a>
                              ) : (
                                <p>No video link available</p>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">Photos</h2>
                        <div className="row">
                          {images.length > 0 ? (
                            images.map((img, index) => (
                              <div key={index} className="mb-3 col-6">
                                <ModalImage
                                  small={img}
                                  large={img}
                                  alt={`Photo ${index}`}
                                  className="profile-add-img"
                                />
                              </div>
                            ))
                          ) : (
                            <p>No Picture Found</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">CAREER TROPHIES</h2>
                        <div className="default-links">
                          {playerdata?.carrier_trophies?.map((trophies) => {
                            console.log(trophies);
                            return (
                              <ul className="m-0">
                                <li>
                                  <h6>
                                    {trophies.trophy === 0
                                      ? "Youth Trophies"
                                      : trophies.trophy === 1
                                      ? "Local Championships"
                                      : trophies.trophy === 2
                                      ? "Local Cups"
                                      : trophies.trophy === 3
                                      ? "European Champions League"
                                      : trophies.trophy === 4
                                      ? "European Cups"
                                      : "National Team Cups"}
                                  </h6>

                                  <p>
                                    {trophies?.date_of_winning
                                      ? new Date(trophies?.date_of_winning)
                                          .toISOString()
                                          .slice(0, 10)
                                          .replace(/-/g, "/")
                                      : "N/A"}
                                  </p>
                                </li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">CV</h2>
                        <Row>
                         

                          {/* {playerCvUrl ? (
                            <Col lg={12} className="mb-3">
                              <div className="cv-upld-view">
                                <a
                                  className="profile-add-img"
                                  href={playerCvUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={require("../Assets/Images/Player-cv.png")}
                                    alt="Open PDF"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <p>Player’s CV.pdf</p>
                                </a>
                              </div>
                            </Col>
                          ) : (
                            <p>PDF not uploaded yet</p>
                          )} */}

                          <Col lg={12} className="mb-3">
                            <div className="cv-upld-view">
                              {playerCvUrl?.includes("https") ? (
                                <a
                                  className="profile-add-img"
                                  href={playerCvUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={require("../Assets/Images/Player-cv.png")}
                                    alt="Open PDF"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <p>Player’s CV.pdf</p>
                                </a>
                              ) : (
                                <p>Cv not uploaded yet</p>
                              )}
                            </div>
                          </Col>

                          
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
