import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import TeamProfileNav from "../Components/TeamProfileNav";
import { useDispatch } from "react-redux";
import { getTeamdetails } from "../redux/actions/adminActions";
import Logo from "../Assets/Images/Profile-icon.png";

export default function TeamDetailsOverview() {
  const { id } = useParams();
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [showI, setShowI] = useState(false);

  const handleClose = () => setShowI(false);
  const handleShow = () => setShowI(true);

  const [TeamOverViewdata, setoveriewData] = useState("");
  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getTeamdetails({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setoveriewData(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Team Details</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/teams">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <TeamProfileNav id={id} />
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <Figure className="d-flex">
                        <span>
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              TeamOverViewdata?.profile_image
                                ? `${url}${TeamOverViewdata?.profile_image}`
                                : Logo
                            }
                          />
                        </span>
                        <div className="figure-caption">
                          <div className="customer-form-new border-none mb-4">
                            <div className="">
                              <h3>Team</h3>
                              <Row>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Team name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        TeamOverViewdata?.team_name || "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Team country</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        TeamOverViewdata?.team_country || "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Team City </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        TeamOverViewdata?.team_city || "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Team owner </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        TeamOverViewdata?.team_owner || "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>

                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Team President</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        TeamOverViewdata?.team_president ||
                                        "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Team league</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        TeamOverViewdata?.team_league === 0
                                          ? "Premier/Top League"
                                          : TeamOverViewdata?.team_league === 1
                                          ? "1st Division"
                                          : TeamOverViewdata?.team_league === 2
                                          ? " 2nd Division"
                                          : TeamOverViewdata?.team_league === 3
                                          ? " 3rd Division"
                                          : " 4th Division"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="">
                                    <Form.Label>Annual Budget</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        TeamOverViewdata?.annual_budget ||
                                        "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Figure>
                    </Col>
                  </Row>
                </div>
                <div className="user-profile-main mt-4">
                  <Row>
                    <Col lg={12}>
                      <div className="customer-form-new border-none mb-4">
                        <div className="">
                          <h3 className="">Contact</h3>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    TeamOverViewdata?.phone_number ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    TeamOverViewdata?.email ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Fax</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    TeamOverViewdata?.fax ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Home address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    TeamOverViewdata?.address ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Contact person name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    TeamOverViewdata?.address ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    TeamOverViewdata?.abc ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Contact number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"  
                                  value={
                                    TeamOverViewdata?.contact_person_phone_number ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>

                            {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">SOCIAL MEDIA</h2>
                        <div className="default-links">
                        <ul>
                            <li>
                              <h6>Facebook</h6>
                              {TeamOverViewdata?.facebook ? (
                                <a
                                  href={TeamOverViewdata.facebook}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="video-link"
                                >
                                  {TeamOverViewdata.facebook}
                                </a>
                              ) : (
                                <p>N/A</p>
                              )}
                            </li>
                            <li>
                              <h6>Instagram</h6>
                              {TeamOverViewdata?.instagram ? (
                                <a
                                  href={TeamOverViewdata.instagram}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="video-link"
                                >
                                  {TeamOverViewdata.instagram}
                                </a>
                              ) : (
                                <p>N/A</p>
                              )}
                            </li>
                            <li>
                              <h6>Snapchat</h6>
                              {TeamOverViewdata?.snapchat ? (
                                <a
                                  href={TeamOverViewdata.snapchat}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="video-link"
                                >
                                  {TeamOverViewdata.snapchat}
                                </a>
                              ) : (
                                <p>N/A</p>
                              )}
                            </li>
                            <li>
                              <h6>X</h6>
                              {TeamOverViewdata?.twitter ? (
                                <a
                                  href={TeamOverViewdata.twitter}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="video-link"
                                >
                                  {TeamOverViewdata.twitter}
                                </a>
                              ) : (
                                <p>N/A</p>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">CAREER TROPHIES</h2>
                        <div className="default-links">
                        {TeamOverViewdata?.carrier_trophies?.map((trophies) => {
                            console.log(trophies);
                            return (
                              <ul className="m-0">
                                <li>
                                  <h6>
                                    {trophies.trophy === 0
                                      ? "Youth Trophies"
                                      : trophies.trophy === 1
                                      ? "Local Championships"
                                      : trophies.trophy === 2
                                      ? "Local Cups"
                                      : trophies.trophy === 3
                                      ? "European Champions League"
                                      : trophies.trophy === 4
                                      ? "European Cups"
                                      : "National Team Cups"}
                                  </h6>

                                  <p>
                                    {trophies?.date_of_winning
                                      ? new Date(trophies?.date_of_winning)
                                          .toISOString()
                                          .slice(0, 10)
                                          .replace(/-/g, "/")
                                      : "N/A"}
                                  </p>
                                </li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
